import React from "react"
import Layout from "../components/Layout"
import Vines from "../components/vines"

const NotFound = () => {
  return (
    <Layout>
      <div className="w-full h-screen bg-white ">
        <div class="flex items-center justify-center h-screen">
          <div class="w-1/2 text-xl -mt-128 font-title text-center ">
            <strong>404</strong>
            <br />
            Aquesta pàgina no existeix...
            <Vines />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFound
